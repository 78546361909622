const location = window.location.href;
const server = location.includes("localhost")
  ? "https://v3-5api.pocsample.in" 
  : location.includes("widget-test")
  ? "https://v3-5api.pocsample.in" 
  : location.includes("widgets")
  ? location.includes("https")
    ? "https://v3-5api.wilyersignage.com"
    : "http://nonsslv3-5api.wilyersignage.com"
  : "http://192.168.1.103:7400";


const footballServer = location.includes("localhost")
  ? "http://localhost:7401"
  : "https://football.wilyersignage.com";
const cricketServer = location.includes("localhost")
  ? "http://localhost:7402"
  : "";

module.exports = {
  server,
  footballServer,
  cricketServer,
};
